import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { PdfModalComponent } from 'src/app/home/pdf-modal/pdf-modal.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit, OnDestroy {

  userSubs: Subscription = new Subscription();
  uiSubs: Subscription = new Subscription();

  hide = true;
  loading = false;

  email = "";
  password = "";

  recoverPassword = false;
  token="";

  constructor( private authService:AuthService, private store: Store<AppState>, private router: Router, public dialog: MatDialog ,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.paramMap.get("id");
    this.userSubs = this.store.select('user').subscribe( (data) => {
      /*if(data != null){
        this.router.navigate(['panel']);
      }*/
    } )

    this.uiSubs = this.store.select('ui').subscribe( ui=>{
      this.loading = ui.isLoading;
    } )
  }

  ngOnDestroy(){
    this.userSubs.unsubscribe();
  }

  login() {

    const patronEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!patronEmail.test(this.email)){
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error, intente de nuevo.',
        text:'Favor de introducir una dirección de correo electrónico válida.',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
      return
    }

    let user = {"email": (this.email),"password": (this.password),"time_zone": 'America/Regina',"role": "patient", "gnp":true};
    this.authService.login(user);
  }

  recoverPass(value){
    this.recoverPassword = value;
  }

  rePass() {
    let data = {
      email: this.email
    }
    this.loading = true;
    this.authService.rePassRequest(data).subscribe((d: any) => {
      let decData = JSON.parse(this.authService.decrypt(d.message, "public"));
      this.loading = false;
      Swal.fire({
        icon: 'success',
        title: 'Envio exitoso!',
        text: decData.data.items[0].message + this.email,
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
      this.recoverPass(false);
    }, err => {

      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error, intente de nuevo.',
        text: err.error.error.errors[0].message,
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
    })
  }

  newPass() {

    let data = {
      password: this.password,
      token: this.token
    }

    console.log(data)

    this.authService.rePassword(data).subscribe(d => {
      console.log(d);
      Swal.fire({
        icon: 'success',
        title: 'Listo',
        text: "Su contraseña ha sido cambiada",
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
      this.router.navigate(['landing']);

    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      let decData = JSON.parse(this.authService.decrypt(err.error.message, "public"));
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: decData.error.errors[0].message,
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
    });
  }

  openPdfModal(): void {
    const dialogRef = this.dialog.open(PdfModalComponent, {
      width: '100%',
      height: '100%',
      position: {
        bottom: '0',
      },
      data: {
        pdfLink: 'https://apignp.mindhelp.mx/aviso_privacidad_mm.pdf', // Reemplaza con tu enlace web del PDF
      },
    });
  }
}
